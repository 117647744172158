import { readState } from "@/__main__/app-state.mjs";
import adsRefs from "@/feature-ads/refs.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";

const originals = mapOriginalRefs({
  adsRefs,
});

export function setup() {
  originals.set({
    adsRefs: {
      // Note that `isFocused` can be undefined, it isn't initialized until
      // a focus/blur event occurs.
      canRefresh: () =>
        !(readState.volatile.isFocused === false && hasRunningGame()),
    },
  });
}

export function teardown() {
  originals.restore();
}

function hasRunningGame() {
  const {
    volatile: { runningGamesState, currentGameTuple, currentSummoner },
  } = readState;
  return (
    Object.getOwnPropertySymbols(runningGamesState || {}).find((s) => {
      return runningGamesState[s];
    }) ||
    currentGameTuple ||
    currentSummoner
  );
}
